import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Day from './day/Day'
import Popup from 'reactjs-popup'
import ReportTable from '../ReportTable'
import {
  getTimelogsByDays,
  IDayTimelog,
  ITimelogsByDays,
  IUserTimelog,
} from '../../utils/fetchTimelogs'
import { convertSecondsToTime } from '../../utils/helpers'
import { IIssue } from '../../utils/fetchIssues'

let weekHours: number = 0
let weekWorkDaysCount: number = 0

interface IMonthProps {
  month: Date
  issues: IIssue[]
  date: Dayjs
  members: string[]
  projectId: string
}

interface IDayData {
  id: number
  day: string
  hours: string
  seconds: number
  data: IUserTimelog[]
}

dayjs.extend(utc)
export default function Month({
  month,
  issues,
  date,
  members,
  projectId,
}: IMonthProps): JSX.Element {
  const [trackData, setTrackData] = useState<IDayData[]>([])
  const [totalTracked, setTotalTracked] = useState<number>(0)
  const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false)
  const [, setIsLoading] = useState<boolean>()
  const [startDate, setStartDate] = useState<string>(
    dayjs().utc().local().startOf('month').format()
  )
  const [endDate, setEndDate] = useState<string>(
    dayjs().utc().local().endOf('month').format()
  )

  const MonthView = (month: Dayjs): JSX.Element[] => {
    let content: JSX.Element[] = []

    const firstMonthDay: number = month.startOf('M').isoWeekday()
    const lastMonthDay: number = month.endOf('M').isoWeekday()
    const monthDaysCount: number = month.daysInMonth()

    const daysBeforeCurrentMonth: number = firstMonthDay - 1
    const daysBeforeNextMonth: number = 7 - lastMonthDay

    let counter: number = 1

    for (
      let i: number = -daysBeforeCurrentMonth;
      i <= monthDaysCount + daysBeforeNextMonth;
      i++
    ) {
      // 0 day of the month
      if (i === 0) {
        continue
      }

      let currentDate: Dayjs = getCurrentDate(i, month)

      //const dayNotCurrentMonth: boolean = i < 0 || i > monthDaysCount
      const dayNotCurrentMonth: boolean = false // track all week days

      // create time track text
      const dayHours: string = parseTrackHours(
        trackData,
        currentDate,
        dayNotCurrentMonth
      )
      const dayData: IUserTimelog[] = parseTrackDetails(trackData, currentDate)
      content.push(
        <Day
          key={i + Math.random()}
          day={currentDate}
          dayHours={dayHours}
          month={dayjs(month).month()}
          dayData={dayData}
          issues={issues}></Day>
      )

      let weekHoursCounter: number = 0
      let weekMinsCounter: number = 0

      if (weekHours) {
        weekHoursCounter = Math.floor(weekHours / (60 * 60))
        let divisorForMinutes: number = weekHours % (60 * 60)
        weekMinsCounter = Math.floor(divisorForMinutes / 60)
      }

      let buildedTimeItems: JSX.Element[] = []

      buildedTimeItems.push(<p key='hours'>{weekHoursCounter}ч</p>)

      if (weekMinsCounter > 0) {
        buildedTimeItems.push(<p key='minutes'>{weekMinsCounter}м</p>)
      }

      const weekAllHoursCounter: number = 40
        //(weekWorkDaysCount ? weekWorkDaysCount : 0) * 7 // workDays * hours per day 

      // create total cell
      if (counter % 7 === 0) {
        content.push(
          <div key={i + Math.random()} className='total'>
            <span className='total-week-tracked'>
              <div className='total-user-group'>{buildedTimeItems}</div>
              <p>из</p>
              <p>{weekAllHoursCounter}ч</p>
            </span>
          </div>
        )

        weekHours = 0
        weekWorkDaysCount = 0
      }

      counter++
    }

    return content
  }

  const getTrackData = async (): Promise<void> => {
    setIsLoading(true)

    let timelogs: IDayTimelog[] = []
    let totalHours: number = 0

    const startDateWeeks = dayjs(month).utc().local().startOf('month').subtract(7, 'days').format()
    const endDateWeeks = dayjs(month).utc().local().endOf('month').add(7, 'days').format()
    for (const member of members) {
      const data: ITimelogsByDays = await getTimelogsByDays(
        startDateWeeks,
        endDateWeeks,
        member,
        projectId
      )
      timelogs.push(...data.timelogs)
    }

    for (const member of members) {
      const data: ITimelogsByDays = await getTimelogsByDays(
        startDate,
        endDate,
        member,
        projectId
      )
      totalHours += data.totalSpent
    }

    prepareTrackData(timelogs)
    setTotalTracked(totalHours)
  }

  const prepareTrackData = (data: IDayTimelog[]): void => {
    if (data?.length !== 0) {
      let trackData = []

      data?.forEach((day: IDayTimelog, i: number) => {
        let trackDay = {
          id: i,
          day: day.date,
          hours: convertSecondsToTime(day.totalSpentPerDay),
          seconds: day.totalSpentPerDay,
          data: day.timelogsByUsers,
        }

        trackData.push(trackDay)
        setTrackData(trackData)
        setIsLoading(false)
      })
    } else {
      setTrackData([])
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTrackData()
    // eslint-disable-next-line
  }, [members, projectId, startDate, endDate])

  useEffect(() => {
    setStartDate(dayjs(month).utc().local().startOf('month').format())
    setEndDate(dayjs(month).utc().local().endOf('month').format())
  }, [month])

  return (
    <>
      {MonthView(dayjs(month))}
      <div className='hours-all'>
        <h2>Total hours in a month: {convertSecondsToTime(totalTracked)}</h2>
        <button
          className='not-button hours-all__btn'
          onClick={() => setExportPopupOpen(!exportPopupOpen)}>
          Export as CSV
        </button>
      </div>

      <Popup key={Math.random()} open={exportPopupOpen} closeOnDocumentClick>
        <ReportTable
          month={dayjs(month).format('MMMM')}
          startDate={startDate}
          endDate={endDate}
          members={members}
          project={projectId}
          togglePopup={() => setExportPopupOpen(!exportPopupOpen)}
        />
      </Popup>
    </>
  )
}

const getCurrentDate = (index: number, month: Dayjs): Dayjs => {
  const currentYear: string = month.format('YYYY')
  const currentMonth: string = month.format('MM')
  const monthDaysCount: number = month.daysInMonth()

  let currentDate: Dayjs

  if (index < 0) {
    currentDate = dayjs(
      currentYear + '-' + currentMonth + '-' + month.startOf('M').format('DD')
    )
    currentDate = currentDate.subtract(Math.abs(index), 'd')
  } else if (index > 0) {
    currentDate = dayjs(
      currentYear + '-' + currentMonth + '-' + Math.abs(index)
    )
  } else if (index > monthDaysCount) {
    currentDate = dayjs(
      currentYear + '-' + currentMonth + '-' + month.startOf('M').format('DD')
    )
    currentDate = currentDate.add(index, 'd')
  }

  return currentDate
}

const parseTrackHours = (
  data: IDayData[],
  date: Dayjs,
  dayNotCurrentMonth: boolean
): string => {
  const dayData = data.filter(
    (e: IDayData) => e.day === date.format('YYYY-MM-DD')
  )

  let dayVisibleData: string

  if (dayData.length !== 0) {
    dayVisibleData = convertSecondsToTime(
      dayData
        .map((el: IDayData) => el.seconds)
        .reduce((partSum: number, a: number) => partSum + a, 0)
    )
  }

  // calculate week track time
  if (!dayNotCurrentMonth && dayData.length > 0) {
    weekHours += dayData
      .map((el: IDayData) => el.seconds)
      .reduce((partSum, a) => partSum + a, 0)
  }

  // calculate work week days in current month
  if (
    date.isoWeekday() !== 6 &&
    date.isoWeekday() !== 7 &&
    !dayNotCurrentMonth
  ) {
    weekWorkDaysCount++
  }

  return dayVisibleData
}

const parseTrackDetails = (data: IDayData[], date: Dayjs): IUserTimelog[] => {
  const dayData: IDayData[] = data.filter(
    e => e.day === date.format('YYYY-MM-DD')
  )

  if (dayData.length !== 0) {
    return dayData.map(el => el.data).reduce((acc, a) => [...acc, ...a], [])
  }

  return []
}
