export interface IUserData {
  username: string
}

interface IResponseData {
  data: {
    currentUser: IUserData
  }
}

export default async function fetchUserData(): Promise<IUserData> {
  const baseUrl: string = process.env.REACT_APP_GITLAB_URL + '/api/graphql'
  const query: string = JSON.stringify({
    query: `{
      currentUser {
        username
      }
    }`,
  })

  return new Promise((resolve, reject) => {
    fetch(baseUrl, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: query,
    })
      .then((res: Response) => res.json())
      .then((data: IResponseData) => {
        resolve(data.data.currentUser)
      })
  })
}
