/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from 'react'
import { checkToken } from '../utils/helpers'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export default function (ComposedComponent): JSX.Element {
  const RequireAuth = (): JSX.Element => {
    const navigate: NavigateFunction = useNavigate()

    useEffect(() => {
      checkToken().catch(() => {
        navigate('/login')
      })
    }, [navigate])

    return <ComposedComponent />
  }

  return <RequireAuth />
}
