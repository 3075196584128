import { useLayoutEffect, useState } from 'react'
import { useLocation, Navigate, Location } from 'react-router-dom'
import { checkToken } from '../utils/helpers'

export default function Home(): JSX.Element {
  let location: Location = useLocation()

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  useLayoutEffect(() => {
    checkToken()
      .then(() => {
        setIsLoggedIn(true)
      })
      .catch(() => {
        setIsLoggedIn(false)
      })
  }, [location.key])

  return isLoggedIn ? <Navigate to='/dashboard' /> : <Navigate to='/login' />
}
