import React from 'react'
import IconChevronDown from '../../../images/icon-chevron-down.svg'
import ReactDatePicker from 'react-datepicker'

interface IDatePickerCustomInputProps {
  selected: Date
  setMonth: React.Dispatch<React.SetStateAction<Date>>
}

export default function DatePickerCustomInput({
  selected,
  setMonth,
}: IDatePickerCustomInputProps): JSX.Element {
  const CustomInput = React.forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }: any, ref) => (
      <button className='not-button change-date' onClick={onClick} ref={ref}>
        <span>{value}</span>
        <img src={IconChevronDown} alt='choose' />
      </button>
    )
  )

  return (
    <ReactDatePicker
      selected={selected}
      onChange={setMonth}
      dateFormat='MMMM yyyy'
      showMonthYearPicker
      showFullMonthYearPicker
      customInput={<CustomInput />}
    />
  )
}
