import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import fetchUserProjects, { IProject } from '../../../utils/fetchProjects'

interface IProjectSelectProps {
  project: IProject
  setProject: React.Dispatch<React.SetStateAction<IProject>>
  setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>
}

export default function ProjectSelect({
  project,
  setProject,
  setSelectedMembers,
}: IProjectSelectProps): JSX.Element {
  const [projects, setProjects] = useState<IProject[]>([])

  useEffect(() => {
    fetchUserProjects().then((projects: IProject[]) => setProjects(projects))
  }, [])

  return (
    <FormControl className='page-header__change-project'>
      <InputLabel className='change-label' id='change-project'>
        Проект
      </InputLabel>
      <Select
        labelId='change-project'
        value={JSON.stringify([project.fullPath, project.id])}
        onChange={e => {
          const project: IProject = {
            fullPath: JSON.parse(e.target.value)[0],
            id: JSON.parse(e.target.value)[1],
          }
          setProject(project)
          setSelectedMembers([''])
        }}
        className='change-input'>
        {projects.map((project: IProject, id: number) => {
          return (
            <MenuItem
              key={id}
              className='change-option'
              value={JSON.stringify([project.fullPath, project.id])}>
              <ListItemText
                className='change-input__value'
                primary={project.name}
              />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
