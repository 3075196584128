export interface IProject {
  fullPath: string
  id: string
  name?: string
}

interface IResponseNode {
  project: IProject
}

export default async function fetchUserProjects(): Promise<IProject[]> {
  const baseUrl: string = process.env.REACT_APP_GITLAB_URL + '/api/graphql'
  const query: string = JSON.stringify({
    query: `{
      currentUser {
        projectMemberships {
          nodes {
            project {
              name,
              id,
              fullPath,
            }
          }
        },
        groupMemberships {
          nodes {
            group {
              projects {
                nodes {
                  name,
                  id,
                  fullPath,
                }
              }
            }
          }
        }
      }
    }`,
  })

  return new Promise((resolve, reject) => {
    let projects: IProject[] = []

    fetch(baseUrl, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: query,
    })
      .then((res: Response) => res.json())
      .then((data: any) => {
        data.data.currentUser.projectMemberships.nodes.forEach(
          (node: IResponseNode) => {
            projects.push(node.project)
          }
        )

        data.data.currentUser.groupMemberships.nodes.forEach((node: any) => {
          node.group.projects.nodes.forEach((project: IProject) =>
            projects.push(project)
          )
        })

        resolve(projects)
      })
  })
}
