export interface IMember {
  id: string
  name: string
  username: string
  webUrl: string
}

interface IResponseNode {
  user: IMember
}

const baseUrl: string = process.env.REACT_APP_GITLAB_URL + '/api/graphql'

export default async function fetchMembers(
  project: string
): Promise<IMember[]> {
  let endCursor: string = ''

  async function fetchNextMembers(
    resolve,
    reject,
    members: IMember[],
    project: string
  ) {
    let query: string = JSON.stringify({
      query: `query {
          project(fullPath:"${project}") {
            projectMembers(after:"${endCursor}") {
              pageInfo {
                hasNextPage
                endCursor
              }
              nodes {
                user {
                  id
                  username
                  name
                  webUrl
                }
              }
            }
          }
        }`,
    })

    let allMembers: IMember[] = members

    fetch(baseUrl, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: query,
    })
      .then((res: Response) => res.json())
      .then((data: any) => {
        let members: IMember[] = []

        data.data.project.projectMembers.nodes.map((node: IResponseNode) => {
          return members.push(node.user)
        })

        allMembers = [...allMembers, ...members]

        if (data.data.project.projectMembers.pageInfo.hasNextPage) {
          endCursor = data.data.project.projectMembers.pageInfo.endCursor
          fetchNextMembers(resolve, reject, allMembers, project)
        } else {
          resolve(allMembers)
        }
      })
  }

  let query: string = JSON.stringify({
    query: `query {
        project(fullPath:"${project}") {
          projectMembers {
            pageInfo {
              hasNextPage
              endCursor
            }
            nodes {
              user {
                id
                username
                name
                webUrl
              }
            }
          }
        }
      }`,
  })

  return new Promise((resolve, reject) => {
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      body: query,
    })
      .then((res: Response) => res.json())
      .then(async (data: any) => {
        let members: IMember[] = []

        data.data.project.projectMembers.nodes.map((node: IResponseNode) => {
          return members.push(node.user)
        })

        if (data.data.project.projectMembers.pageInfo.hasNextPage) {
          endCursor = data.data.project.projectMembers.pageInfo.endCursor
          members = await new Promise((r, j) =>
            fetchNextMembers(r, j, members, project)
          )
        }

        resolve(members)
      })
  })
}
