import React from 'react'
import { IIssue } from '../../../utils/fetchIssues'

interface IIssueSelectProps {
  issues: IIssue[]
  setIssue: React.Dispatch<React.SetStateAction<string>>
}

export default function IssueSelect({
  issues,
  setIssue,
}: IIssueSelectProps): JSX.Element {
  return (
    <select
      className='task-input-select'
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        setIssue(e.target.value)
      }>
      <option value=''>Select an issue</option>
      {issues.map((issue: IIssue, i: number) => {
        return (
          <option value={issue.id} key={i}>
            #{issue.iid} {issue.title}
          </option>
        )
      })}
    </select>
  )
}
