import { useEffect } from 'react'
import { useState } from 'react'
import { DataGrid, GridPagination } from '@mui/x-data-grid'
import { CSVLink } from 'react-csv'
import { getTimelogsByIssues, ITimelogByIssues } from '../utils/fetchTimelogs'
import { convertSecondsToTime } from '../utils/helpers'
import { useMemo } from 'react'

import IconDownload from '../images/icon-download.svg'
import IconClose from '../images/icon-close.svg'

interface ISummary {
  humanHours: string
  machineHours: number
}

interface ISummaryFooterProps {
  summary: ISummary
}

interface IReportTableProps {
  month: string
  startDate: string
  endDate: string
  members: string[]
  project: string
  togglePopup: () => void
}

interface ITableColumn {
  field: string
  headerName: string
  width: number
  editable: boolean
}

interface ITableRow {
  milestone: string
  timeSpent: number
  humanHours: string
  machineHours: number
  issue: string
  id: string
}

interface ICsvHeader {
  label: string
  key: string
}

interface IEditParams {
  id: string
  field: string
  value: string
}

function SummaryFooter({ summary }: ISummaryFooterProps): JSX.Element {
  return (
    <div>
      Total: {summary.machineHours} / {summary.humanHours}
      <GridPagination />
    </div>
  )
}

export default function ReportTable({
  month,
  startDate,
  endDate,
  project,
  members,
  togglePopup,
}: IReportTableProps): JSX.Element {
  const [rows, setRows] = useState<ITableRow[]>([])
  const [key, setKey] = useState<number>(0)
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false)

  let longCollWidth: number = 200
  let shortCollWidth: number = 100

  const columns: ITableColumn[] = [
    {
      field: 'milestone',
      headerName: 'Milestone',
      width: longCollWidth,
      editable: true,
    },
    {
      field: 'issue',
      headerName: 'Issue',
      width: longCollWidth,
      editable: true,
    },
    {
      field: 'machineHours',
      headerName: 'Machine Hours',
      width: shortCollWidth,
      editable: true,
    },
    {
      field: 'humanHours',
      headerName: 'Human Hours',
      width: shortCollWidth,
      editable: true,
    },
  ]

  const csvHeaders: ICsvHeader[] = [
    { label: 'Milestone', key: 'milestone' },
    { label: 'Issue', key: 'issue' },
    { label: 'Machine Hours', key: 'machineHours' },
    { label: 'Human Hours', key: 'humanHours' },
  ]

  const summary: ISummary = useMemo(() => {
    return {
      humanHours: convertSecondsToTime(
        rows
          .map(row => row.timeSpent)
          .reduce((partSum: number, a: number) => partSum + a, 0)
      ),
      machineHours:
        rows
          .map(row => row.timeSpent)
          .reduce((partSum: number, a: number) => partSum + a, 0) /
        60 /
        60,
    }
  }, [rows])

  useEffect(() => {
    async function getAllUsersTimelogs(): Promise<void> {
      let acc: ITableRow[] = []
      setIsLoading(true)

      for (let member of members) {
        const data: ITimelogByIssues[] = await getTimelogsByIssues(
          startDate,
          endDate,
          member,
          project
        )
        data.forEach((row: ITimelogByIssues, i: number) => {
          let rowData: ITableRow = {
            milestone: row.milestone,
            issue: row.issue,
            humanHours: row.humanHours,
            machineHours: row.machineHours,
            timeSpent: row.timeSpent,
            id: `${i}_${member}`,
          }
          acc.push(rowData)
        })
      }

      setRows(acc)
      setIsLoading(false)
    }

    getAllUsersTimelogs()
  }, [startDate, endDate, members, project])

  const editData = (params: IEditParams) => {
    let tempRows: ITableRow[] = rows
    setRows(null)

    tempRows.forEach((row: ITableRow) => {
      if (row.id === params.id) {
        row[params.field] = params.value
        setRows(tempRows)
        setKey(key + 1)
      }
    })
  }

  return (
    <div className='report-popup'>
      <div className='report-popup__header'>
        <div className='report-popup-header-label'>
          <h2 className='report-popup__title'>Отчет за {month}</h2>
        </div>
        <div className='report-popup-header-actions'>
          <CSVLink
            className='report-popup__btn-download'
            key={key}
            data={rows}
            target='_blank'
            headers={csvHeaders}
            filename={`report_${startDate}_${endDate}`}>
            <img className='download-icon' src={IconDownload} alt='download' />
          </CSVLink>

          <button
            className='not-button popup-header-close'
            onClick={togglePopup}>
            <img className='close-icon' src={IconClose} alt='close' />
          </button>
        </div>
      </div>
      <div className='report-popup__body'>
        <div className='table-wrapper'>
          <DataGrid
            rows={rows}
            columns={columns}
            onCellEditCommit={editData}
            components={{
              Footer: SummaryFooter,
            }}
            componentsProps={{
              footer: { summary },
            }}
          />
        </div>
      </div>
    </div>
  )
}
