import { Outlet } from 'react-router-dom'

import '../css/index.css'
import '../css/app.css'

export default function Layout(): JSX.Element {
  return (
    <>
      {/* <Menu /> */}
      <Outlet />
    </>
  )
}
