import dayjs from 'dayjs'
import { useState } from 'react'
import Popup from 'reactjs-popup'
import { IIssue } from '../../../utils/fetchIssues'
import { IUserTimelog } from '../../../utils/fetchTimelogs'
import InfoModal from './InfoModal'

export interface IDayProps {
  day: any
  dayData: IUserTimelog[]
  dayHours: string
  issues: IIssue[]
  month: number
}

export default function Day({
  day,
  dayHours,
  dayData,
  month,
  issues,
}: IDayProps): JSX.Element {
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false)

  const getClassName = (): string => {
    let className = 'day'

    if ([6, 7].indexOf(day.isoWeekday()) > -1) {
      className += ' day-weekend'
    }

    if (day.month() !== month) {
      className += ' day-not-current-month'
    }

    if (day.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
      className += ' day-current'
    }

    return className
  }

  return (
    <div
      title='Подробнее'
      className={getClassName()}
      onClick={() => setInfoModalOpen(!infoModalOpen)}>
      <span className='day-time'>{dayHours}</span>
      <span className='day-counter'>{day.format('D')}</span>

      <Popup open={infoModalOpen} closeOnDocumentClick>
        <InfoModal
          day={day}
          dayHours={dayHours}
          dayData={dayData}
          issues={issues}
          setOpen={setInfoModalOpen}
        />
      </Popup>
    </div>
  )
}
