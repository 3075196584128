import dayjs from 'dayjs'
import { convertSecondsToTime } from './helpers'

export interface ITimelog {
  id: String
  issue: any
  note: string
  spentAt: string
  timeSpent: number
  user: any
  project: {
    name: string
    fullPath: string
  }
}

export interface ITimelogsByDays {
  totalSpent: number
  timelogs: IDayTimelog[]
}

export interface IDayTimelog {
  date: string
  timelogsByUsers: IUserTimelog[]
  totalSpentPerDay: number
}

export interface IUserTimelog {
  timelogs: ITimelog[]
  totalSpentByUser: number
  username: string
}

export interface ITimelogByIssues {
  humanHours: string
  issue: string
  machineHours: number
  milestone: string
  timeSpent: number
}

const baseUrl: string = process.env.REACT_APP_GITLAB_URL + '/api/graphql'
let endCursor: string = ''

const fetchNextTimelogs = async (
  resolve,
  reject,
  data,
  timelogs: ITimelog[]
) => {
  let allTimelogs: ITimelog[] = timelogs

  fetch(baseUrl, {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      query: `{
        timelogs(
          startDate:"${data.startDate}",
          endDate: "${data.endDate}"
          ${data.project ? `projectId: "${data.project}"` : ''}
          after: "${endCursor}"
          ${data.member ? `username: "${data.member}"` : ''}
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id,
            timeSpent,
            project {
              #id,
              name,
              fullPath
            },
            note {
              body
            },
            issue {
              iid,
              title
            },
            user {
              username,
              id
            },
            spentAt
          }
        }
      }`,
    }),
  })
    .then((res: Response) => res.json())
    .then((data: any) => {
      allTimelogs = [...allTimelogs, ...data.data.timelogs?.nodes]

      if (data.data.timelogs.pageInfo.hasNextPage) {
        endCursor = data.data.timelogs.pageInfo.endCursor
        fetchNextTimelogs(resolve, reject, data, allTimelogs)
      } else {
        resolve(allTimelogs)
      }
    })
}

async function fetchTimelogs(
  startDate: string,
  endDate: string,
  member: string,
  project: string
): Promise<ITimelog[]> {
  const query: string = JSON.stringify({
    query: `{
      timelogs(
        startDate:"${startDate}", 
        endDate: "${endDate}"
        ${project ? `projectId: "${project}"` : ''}
        ${member ? `username: "${member}"` : ''}
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id,
          timeSpent,
          project {
            #id,
            name,
            fullPath
          },
          note {
            body
          },
          issue {
            iid,
            title,
            milestone {
              title
            }
          },
          user {
            username,
            id
          },
          spentAt
        }
      }
    }`,
  })

  return new Promise((resolve, reject) => {
    fetch(baseUrl, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: query,
    })
      .then((res: Response) => res.json())
      .then(async (data: any) => {
        endCursor = data.data.timelogs.pageInfo.endCursor

        let hasNextPage: boolean = data.data.timelogs.pageInfo.hasNextPage
        let timelogs: ITimelog[] = data.data.timelogs?.nodes

        if (hasNextPage) {
          const data = {
            startDate,
            endDate,
            project,
            member,
          }

          timelogs = await new Promise((r, j) =>
            fetchNextTimelogs(r, j, data, timelogs)
          )
        }

        timelogs = timelogs.filter(timelog => timelog.issue)
        resolve(timelogs)
      })
      .catch(err => {
        console.log(err)
      })
  })
}

export async function getTimelogsByDays(
  startDate: string,
  endDate: string,
  member: string,
  project: string
): Promise<ITimelogsByDays> {
  return new Promise((resolve, reject) => {
    fetchTimelogs(startDate, endDate, member, project).then(
      (timelogs: ITimelog[]) => {
        let timelogsByDays: ITimelogsByDays = {
          totalSpent: 0,
          timelogs: [],
        }

        timelogs.forEach((timelog: ITimelog) => {
          timelog.spentAt = dayjs(timelog.spentAt).format('YYYY-MM-DD')
          let timelogDay: IDayTimelog = timelogsByDays.timelogs.find(
            (el: IDayTimelog) => el.date === timelog.spentAt
          )
          timelogsByDays.totalSpent += timelog.timeSpent

          // {[ date: '2022-01-01', ... ]}
          if (!timelogDay) {
            timelogDay = {
              date: timelog.spentAt,
              totalSpentPerDay: 0,
              timelogsByUsers: [],
            }

            timelogsByDays.timelogs.push(timelogDay)
          }

          timelogDay.totalSpentPerDay += timelog.timeSpent

          let timelogUser: IUserTimelog = timelogDay.timelogsByUsers.find(
            (el: IUserTimelog) => {
              return el.username === timelog.user.username
            }
          )

          if (!timelogUser) {
            timelogUser = {
              username: timelog.user.username,
              totalSpentByUser: 0,
              timelogs: [],
            }

            timelogDay.timelogsByUsers.push(timelogUser)
          }

          timelogUser.totalSpentByUser += timelog.timeSpent
          timelogUser.timelogs.push(timelog)
        })

        resolve(timelogsByDays)
      }
    )
  })
}

export async function getTimelogsByIssues(
  startDate: string,
  endDate: string,
  member: string,
  project: string
): Promise<ITimelogByIssues[]> {
  return new Promise((resolve, reject) => {
    fetchTimelogs(startDate, endDate, member, project).then(
      (timelogs: ITimelog[]) => {
        let timelogsByIssues: ITimelogByIssues[] = []

        timelogs.forEach((timelog: ITimelog) => {
          let timelogIssue: ITimelogByIssues = timelogsByIssues.find(
            (el: ITimelogByIssues) => {
              return el.issue === timelog.issue?.title
            }
          )

          if (!timelogIssue) {
            timelogIssue = {
              issue: timelog.issue?.title,
              milestone: timelog.issue?.milestone?.title,
              timeSpent: 0,
              machineHours: 0,
              humanHours: '',
            }

            timelogsByIssues.push(timelogIssue)
          }

          timelogIssue.timeSpent += timelog.timeSpent
        })

        timelogsByIssues = timelogsByIssues.filter(
          (timelog: ITimelogByIssues) => timelog.timeSpent > 0
        )

        timelogsByIssues.forEach((timelog: ITimelogByIssues) => {
          if (!timelog.milestone) {
            timelog.milestone = 'No milestone'
          }

          timelog.machineHours = timelog.timeSpent / 60 / 60
          timelog.humanHours = convertSecondsToTime(timelog.timeSpent)
        })

        timelogsByIssues.sort(
          (a: ITimelogByIssues, b: ITimelogByIssues): number => {
            if (a.milestone > b.milestone) return 1
            if (a.milestone < b.milestone) return -1
            return 0
          }
        )

        resolve(timelogsByIssues)
      }
    )
  })
}
