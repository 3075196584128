import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect } from 'react'
import { IUserData } from '../../../utils/fetchUserData'
import { IMember } from '../../../utils/fetchMembers'
import fetchUserData from '../../../utils/fetchUserData'

interface IMemberSelectProps {
  selectedMembers: string[]
  members: IMember[]
  setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>
}

export default function MemberSelect({
  selectedMembers,
  members,
  setSelectedMembers,
}: IMemberSelectProps): JSX.Element {
  useEffect(() => {
    fetchUserData().then((user: IUserData) =>
      setSelectedMembers([user.username])
    )
  }, [setSelectedMembers])

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    let newMembers: string[] =
      typeof value === 'string' ? value.split(',') : value
    newMembers = newMembers.filter((m: string) => m !== '')
    setSelectedMembers(newMembers)
  }

  return (
    <FormControl className='page-header__change-user'>
      <InputLabel className='change-label' id='change-user'>
        Members
      </InputLabel>
      <Select
        labelId='change-user'
        multiple
        value={selectedMembers}
        onChange={handleChange}
        input={<OutlinedInput label='Members' />}
        renderValue={(selected: string[]) => selected.join(', ')}
        className='change-input'>
        {members.map((member: IMember, id: number) => {
          return (
            <MenuItem
              className='change-option'
              key={id}
              value={member.username}>
              <Checkbox
                checked={
                  selectedMembers.indexOf(member.username) > -1
                }></Checkbox>
              <ListItemText
                className='change-input__value'
                primary={member.name}
              />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
