export interface IIssue {
  id: string
  iid: string
  title: string
}

const baseUrl: string = process.env.REACT_APP_GITLAB_URL + '/api/graphql'

export default async function fetchIssues(project: string): Promise<IIssue[]> {
  let endCursor: string = ''

  async function fetchNextIssues(
    resolve,
    reject,
    issues: IIssue[],
    project: string
  ) {
    let query = JSON.stringify({
      query: `query {
          project(fullPath:"${project}") {
            issues(after:"${endCursor}") {
              pageInfo {
                hasNextPage
                endCursor
              }
              nodes {
                id
                iid
                title
              }
            }
          }
        }`,
    })

    let allIssues: IIssue[] = issues

    fetch(baseUrl, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: query,
    })
      .then((res: Response) => res.json())
      .then((data: any) => {
        allIssues = [...allIssues, ...data.data.project.issues.nodes]

        if (data.data.project.issues.pageInfo.hasNextPage) {
          endCursor = data.data.project.issues.pageInfo.endCursor
          fetchNextIssues(resolve, reject, allIssues, project)
        } else {
          resolve(allIssues)
        }
      })
  }
  let query: string = JSON.stringify({
    query: `query {
        project(fullPath:"${project}") {
          issues {
            pageInfo {
              hasNextPage
              endCursor
            }
            nodes {
              id
              iid
              title
            }
          }
        }
      }`,
  })

  return new Promise((resolve, reject) => {
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      body: query,
    })
      .then((res: Response) => res.json())
      .then(async (data: any) => {
        let issues: IIssue[] = data.data.project.issues.nodes

        if (data.data.project.issues.pageInfo.hasNextPage) {
          endCursor = data.data.project.issues.pageInfo.endCursor
          issues = await new Promise((r, j) =>
            fetchNextIssues(r, j, issues, project)
          )
        }

        resolve(issues)
      })
  })
}
