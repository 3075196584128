export interface IProjects {
  path: string
}

export interface IMember {
  id: string
  name: string
  username: string
  webUrl: string
}

interface IResponseNode {
  node: IMember
}

const baseUrl: string = process.env.REACT_APP_GITLAB_URL + '/api/graphql'

export default async function fetchAllMembers(): Promise<IMember[]> {
  let endCursor: string = ''

  async function fetchNextMembers(
    resolve,
    reject,
    members: IMember[]
  ) {
    let query: string = JSON.stringify({
      query: `query {
          users(after:"${endCursor}") {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                username
                webUrl
              }
            }
          }
        }`,
    })

    let allMembers: IMember[] = members

    fetch(baseUrl, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: query,
    })
      .then((res: Response) => res.json())
      .then((data: any) => {
        let members: IMember[] = []

        data.data.users.edges.map((node: IResponseNode) => {
          return members.push(node.node)
        })

        allMembers = [...allMembers, ...members]

        if (data.data.users.pageInfo.hasNextPage) {
          endCursor = data.data.users.pageInfo.endCursor
          fetchNextMembers(resolve, reject, allMembers)
        } else {
          resolve(allMembers)
        }
      })
  }

  let query: string = JSON.stringify({
    query: `query {
        users {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              name
              username
              webUrl
            }
          }
        }
      }
    `,
  })

  return new Promise((resolve, reject) => {
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('oauth_access_token'),
        'Content-Type': 'application/json',
      },
      body: query,
    })
      .then((res: Response) => res.json())
      .then(async (data: any) => {
        let members: IMember[] = []

        data.data.users.edges.map((node: IResponseNode) => {
          return members.push(node.node)
        })

        if (data.data.users.pageInfo.hasNextPage) {
          endCursor = data.data.users.pageInfo.endCursor
          members = await new Promise((r, j) =>
            fetchNextMembers(r, j, members)
          )
        }

        resolve(members)
      })
  })
}
