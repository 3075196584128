import { useState } from 'react'
import { convertSecondsToTime } from '../../../utils/helpers'
import IssueSelect from './IssueSelect'

import IconClose from '../../../images/icon-close.svg'
import { ITimelog, IUserTimelog } from '../../../utils/fetchTimelogs'
import { IIssue } from '../../../utils/fetchIssues'

function UserTimelog({ data }) {
  return (
    <div className='timelog'>
      <h2 className='track-item' key={Math.random()}>
        {data.username}
      </h2>

      {data.timelogs.map((timelog: ITimelog, i) => {
        return (
          <p className='track-item' key={i}>
            <span><a target='_blank' rel="noreferrer" href={`${process.env.REACT_APP_GITLAB_URL}/${timelog?.project?.fullPath}/-/issues/${timelog?.issue?.iid}`}>{timelog?.project?.name}:{timelog?.project?.fullPath}</a></span>
            <span>|</span>
            <span>#{timelog?.issue?.iid}</span>
            <span>{timelog?.issue?.title}</span>
            <span>{convertSecondsToTime(timelog.timeSpent)}</span>
          </p>
        )
      })}
    </div>
  )
}

interface IInfoModalProps {
  day: any
  dayHours: string
  dayData: IUserTimelog[]
  issues: IIssue[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function InfoModal({
  day,
  dayHours,
  dayData,
  issues,
  setOpen,
}: IInfoModalProps): JSX.Element {
  const [trackTime, setTrackTime] = useState<string>('')
  const [issue, setIssue] = useState<string>('')

  const postTimelog = (): void => {
    if (issue && trackTime) {
      fetch(process.env.REACT_APP_GITLAB_URL + `/api/graphql`, {
        headers: {
          Authorization:
            'Bearer ' + sessionStorage.getItem('oauth_access_token'),
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          query: `mutation {
            createNote(input: {noteableId:"${issue}", body:"/spent ${trackTime} ${day.format(
            'YYYY-MM-DD'
          )}"}) {
              errors
            }
          }`,
        }),
      })
        .then(() => {
          setOpen(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <div className='popup'>
      <div className='popup-header'>
        <div className='popup-header-label'>
          <span>{day.format('dddd D MMMM')}</span>
          <span>{dayHours ? dayHours : '0 часов'} из 7</span>
        </div>
        <button
          className='not-button popup-header-close'
          onClick={() => setOpen(false)}>
          <img className='close-btn' src={IconClose} alt='close' />
        </button>
      </div>
      <div className='popup-body'>
        {dayData.map((userData, i) => {
          return <UserTimelog data={userData} key={i} />
        })}
      </div>
      <div className='popup-footer'>
        <IssueSelect issues={issues} setIssue={setIssue} />
        <div className='form-row'>
          <input
            className='task-input-hours'
            placeholder='1h 30m'
            onChange={e => setTrackTime(e.target.value)}></input>
          <button
            className='task-input-add'
            onClick={() => postTimelog()}
            disabled={!issue || !trackTime}>
            Добавить
          </button>
        </div>
      </div>
    </div>
  )
}
