import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { checkToken } from '../utils/helpers'

import '../css/spinner.css'

const accessTokenUrl: string = process.env.REACT_APP_GITLAB_URL + '/oauth/token'

export default function GitlabRedirect(): JSX.Element {
  const [searchParams] = useSearchParams()
  const navigate: NavigateFunction = useNavigate()

  if (
    sessionStorage.getItem('gitlab_oauth_state') === searchParams.get('state')
  ) {
    sessionStorage.removeItem('gitlab_oauth_state')

    const link: string =
      `?client_id=${process.env.REACT_APP_OAUTH_ID}` +
      `&code=${searchParams.get('code')}` +
      `&grant_type=authorization_code` +
      `&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT}` +
      `&code_verifier=${sessionStorage.getItem('gitlab_oauth_code_verifier')}`

    fetch(accessTokenUrl + link, {
      method: 'POST',
    })
      .then((res: Response) => res.json())
      .then(data => {
        sessionStorage.setItem('oauth_access_token', data.access_token)
        sessionStorage.setItem('oauth_refresh_token', data.refresh_token)
        checkToken().then(() => {
          navigate('/dashboard')
        })
      })
      .catch(err => console.log(err))
  }

  return (
    <div className='spinner__container'>
      <p className='spinner__label'>Redirecting...</p>
      <div className='spin'></div>
    </div>
  )
}
