export async function checkToken(): Promise<boolean> {
  const token: string | null = sessionStorage.getItem('oauth_access_token')

  return new Promise((resolve, reject) => {
    if (token === null) {
      refreshToken()
        .then((res: any) => {
          if (res.status === 200) {
            resolve(true)
          }

          reject(false)
        })
        .catch(() => {
          reject(false)
        })
    } else {
      fetch(process.env.REACT_APP_GITLAB_URL + `/api/v4/projects`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res.status === 200) {
            resolve(true)
          }

          if (res.status === 401) {
            refreshToken()
              .then(() => {
                if (res.status === 200) {
                  resolve(true)
                }

                reject(false)
              })
              .catch(() => {
                reject(false)
              })
          }

          reject(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  })
}

export async function refreshToken(): Promise<boolean> {
  const refreshToken: string | null = sessionStorage.getItem(
    'oauth_refresh_token'
  )

  return new Promise((resolve, reject) => {
    if (refreshToken === null) {
      reject(false)
    } else {
      const params: string =
        `client_id=${process.env.REACT_APP_OAUTH_ID}` +
        `&refresh_token=${refreshToken}` +
        `&grant_type=refresh_token` +
        `&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT}` +
        `&code_verifier=${sessionStorage.getItem('gitlab_oauth_code_verifier')}`

      fetch(process.env.REACT_APP_GITLAB_URL + `/oauth/token?${params}`, {
        method: 'POST',
      })
        .then(res => res.json())
        .then(data => {
          if (data.access_token && data.refresh_token) {
            sessionStorage.setItem('oauth_access_token', data.access_token)
            sessionStorage.setItem('oauth_refresh_token', data.refresh_token)
            resolve(true)
          }

          reject(false)
        })
        .catch(err => console.error(err))
    }
  })
}

export function convertSecondsToTime(seconds: number): string {
  let hours: number = Math.floor(seconds / (60 * 60))

  let divisorForMinutes: number = seconds % (60 * 60)
  let minutes: number = Math.floor(divisorForMinutes / 60)

  return `${hours ? `${hours}ч` : ''}${minutes ? ` ${minutes}м` : ''}`
}
