import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
import NoPage from './pages/NoPage'

import IndexPage from './pages/IndexPage'
import LoginPage from './pages/LoginPage'
import DashboardPage from './pages/DashboardPage'
import GitlabRedirect from './pages/GitlabRedirect'
import RequireAuth from './components/RequireAuth'
import Exit from './pages/Exit'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='dashboard' element={RequireAuth(DashboardPage)} />
          <Route path='*' element={<NoPage />} />
          <Route path='oauth/gitlab/redirect' element={<GitlabRedirect />} />
          <Route path='exit' element={<Exit />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
