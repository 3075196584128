import { nanoid } from 'nanoid'
import { useLayoutEffect, useState } from 'react'
import { useLocation, Navigate, Location } from 'react-router-dom'
import { checkToken } from '../utils/helpers'

import '../css/login-page.css'
import GitlabLogo from '../images/gitlab-logo.png'

function dec2hex(dec: number): string {
  return ('0' + dec.toString(16)).substr(-2)
}

function generateCodeVerifier(): string {
  var array: Uint32Array = new Uint32Array(56 / 2)
  window.crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join('')
}

function sha256(plain: string): Promise<ArrayBuffer> {
  const encoder: TextEncoder = new TextEncoder()
  const data: Uint8Array = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}

function base64urlencode(a: ArrayBuffer): string {
  var str: string = ''
  var bytes: Uint8Array = new Uint8Array(a)
  var len: number = bytes.byteLength
  for (var i: number = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i])
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

async function generateCodeChallengeFromVerifier(v: string): Promise<string> {
  var hashed: ArrayBuffer = await sha256(v)
  var base64encoded = base64urlencode(hashed)
  return base64encoded
}

const oauthBaseUrl: string = process.env.REACT_APP_GITLAB_URL + '/oauth/'
const oauthState: string = nanoid(64)
const oauthCodeVerifier: string = generateCodeVerifier()

export default function Login(): JSX.Element {
  const redirectToAuthorization = async (): Promise<void> => {
    const oauthCodeChallenge: string = await generateCodeChallengeFromVerifier(
      oauthCodeVerifier
    )

    const link: string =
      oauthBaseUrl +
      `authorize` +
      `?client_id=${process.env.REACT_APP_OAUTH_ID}` +
      `&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT}` +
      `&response_type=code` +
      `&state=${oauthState}` +
      `&scope=api` +
      `&code_challenge=${oauthCodeChallenge}` +
      `&code_challenge_method=S256`

    sessionStorage.setItem('gitlab_oauth_state', oauthState)
    sessionStorage.setItem('gitlab_oauth_code_verifier', oauthCodeVerifier)

    window.location.replace(link)
  }

  const location: Location = useLocation()

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  useLayoutEffect(() => {
    checkToken()
      .then(() => {
        setIsLoggedIn(true)
      })
      .catch(() => {
        setIsLoggedIn(false)
      })
  }, [location.key])

  return isLoggedIn ? (
    <Navigate to='/dashboard' />
  ) : (
    <div className='fullpage-btn__container'>
      <button
        className='not-button login-btn'
        onClick={() => redirectToAuthorization()}>
        <img src={GitlabLogo} className='login-btn__image' alt='GitLab' />
        <span className='login-btn__label'>Login with Gitlab</span>
      </button>
    </div>
  )
}
